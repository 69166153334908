<template>
  <div>
    <ba-dialog :dialog="dialog" small btnName="Run Payroll" title="Run Payroll Form">
      <v-list three-line subheader>
        <v-subheader>Payroll Controls</v-subheader>
        <v-list-tile avatar>
          <v-list-tile-content>
            <v-list-tile-title>Amount to be paid</v-list-tile-title>
            <v-list-tile-sub-title
              >Set the content filtering level to restrict apps that can be downloaded</v-list-tile-sub-title
            >
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile avatar>
          <v-list-tile-content>
            <v-list-tile-title>Wallet to use</v-list-tile-title>
            <v-list-tile-sub-title
              >Require password for purchase or use password to restrict purchase</v-list-tile-sub-title
            >
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-divider></v-divider>
    </ba-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
