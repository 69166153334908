<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="payroll"
    :pagination.sync="pagination"
    select-all
    item-key="name"
    class="elevation-5"
    hide-actions
  >
    <template slot="headers" slot-scope="props">
      <tr>
        <th
          v-for="header in props.headers"
          :key="header.text"
          :class="[
            'column sortable text-xs-left',
            pagination.descending ? 'desc' : 'asc',
            header.value === pagination.sortBy ? 'active' : '',
          ]"
          @click="changeSort(header.value)"
        >
          <v-icon small>arrow_upward</v-icon>
          {{ header.text }}
        </th>
        <th></th>
      </tr>
    </template>
    <template slot="items" slot-scope="props">
      <tr :active="props.selected" @click="props.selected = !props.selected">
        <td>{{ props.item.name }}</td>
        <td class="text-xs-left">{{ props.item.hourly_rate }}</td>
        <td class="text-xs-left">{{ props.item.amount_due }}</td>
        <td class="text-xs-right">
          <v-btn icon @click.stop="">
            <v-icon>edit</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    pagination: {
      sortBy: 'hourly_rate',
      descending: true,
    },
    selected: [],
    headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'name',
      },
      { text: 'Hourly Rate', value: 'hourly_rate' },
      { text: 'Amount Due', value: 'amount_due' },
    ],
    payroll: [
      {
        name: 'Pat',
        hourly_rate: '.0002฿',
        amount_due: '.0024฿',
      },
      {
        name: 'Panos',
        hourly_rate: '.0001฿',
        amount_due: '.0013฿',
      },
      {
        name: 'Amy',
        hourly_rate: '.0001฿',
        amount_due: '.0032฿',
      },
    ],
  }),

  methods: {
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
  },
};
</script>
