<template>
  <div>
    <v-toolbar flat dark dense class="elevation-5" color="primary">
      <v-toolbar-title>Payroll</v-toolbar-title>
      <v-spacer></v-spacer>
      <runPayroll />
    </v-toolbar>
    <v-tabs class="elevation-5">
      <v-tab> payroll </v-tab>
      <v-tab> employees </v-tab>
      <v-tab> pay stubs </v-tab>
      <v-tab-item>
        <payroll />
      </v-tab-item>
      <v-tab-item>
        <payroll />
      </v-tab-item>
      <v-tab-item>
        <payroll />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Payroll from '../components/Payroll.vue';
import RunPayroll from '../components/RunPayroll.vue';

export default {
  props: [],
  data: () => ({
    pagination: {
      sortBy: 'name',
    },
    selected: [],
    headers: [
      {
        text: 'Wallet',
        align: 'left',
        value: 'name',
      },
      { text: 'Balance', value: 'balance' },
    ],
    wallets: [
      {
        name: 'Corporate - Cold Wallet',
        balance: '123',
      },
      {
        name: 'Corporate - Hot Wallet 1',
        balance: '456',
      },
      {
        name: 'Corporate - Hot Wallet 2',
        balance: '789',
      },
    ],
  }),

  methods: {
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
  },

  components: {
    payroll: Payroll,
    runPayroll: RunPayroll,
  },
};
</script>
